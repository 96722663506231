<template>
  <section class="review-section">
            <div class="review-header text-center">
              <h3 class="review-title">For HRD's Use Only</h3>
              <p class="text-muted">Lorem ipsum dollar</p>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="table-responsive">
                  <table class="table table-bordered review-table mb-0">
                    <thead>
                      <tr>
                        <th>Overall Parameters</th>
                        <th>Available Points</th>
                        <th>Points Scored</th>
                        <th>RO's Comment</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>KRAs Target Achievement Points (will be considered from the overall score specified in this document by the Reporting officer)</td>
                        <td><input type="text" class="form-control" ></td>
                        <td><input type="text" class="form-control" ></td>
                        <td><input type="text" class="form-control" ></td>
                      </tr>
                      <tr>
                        <td>Professional Skills Scores(RO's Points furnished in the skill & attitude assessment sheet will be considered)</td>
                        <td><input type="text" class="form-control" ></td>
                        <td><input type="text" class="form-control" ></td>
                        <td><input type="text" class="form-control" ></td>
                      </tr>
                      <tr>
                        <td>Personal Skills Scores(RO's Points furnished in the skill & attitude assessment sheet will be considered)</td>
                        <td><input type="text" class="form-control" ></td>
                        <td><input type="text" class="form-control" ></td>
                        <td><input type="text" class="form-control" ></td>
                      </tr>
                      <tr>
                        <td>Special Achievements Score (HOD to furnish)</td>
                        <td><input type="text" class="form-control" ></td>
                        <td><input type="text" class="form-control" ></td>
                        <td><input type="text" class="form-control" ></td>
                      </tr>
                      <tr>
                        <td>Overall Total Score</td>
                        <td><input type="text" class="form-control" ></td>
                        <td><input type="text" class="form-control" ></td>
                        <td><input type="text" class="form-control" ></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>

          <div class="row">
            <div class="col-md-12">
              <div class="table-responsive">
                <table class="table table-bordered review-table mb-0">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Name</th>
                      <th>Signature</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Employee</td>
                      <td><input type="text" class="form-control" ></td>
                      <td><input type="text" class="form-control" ></td>
                      <td><input type="text" class="form-control" ></td>
                    </tr>
                    <tr>
                      <td>Reporting Officer</td>
                      <td><input type="text" class="form-control" ></td>
                      <td><input type="text" class="form-control" ></td>
                      <td><input type="text" class="form-control" ></td>
                    </tr>
                    <tr>
                      <td>HOD</td>
                      <td><input type="text" class="form-control" ></td>
                      <td><input type="text" class="form-control" ></td>
                      <td><input type="text" class="form-control" ></td>
                    </tr>
                    <tr>
                      <td>HRD</td>
                      <td><input type="text" class="form-control" ></td>
                      <td><input type="text" class="form-control" ></td>
                      <td><input type="text" class="form-control" ></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
</template>