<template>
  <div class="employeereports">
    <div class="main-wrapper">
      <main-header></main-header>
      <sidebar></sidebar>
      <!-- Page Wrapper -->
      <div class="page-wrapper">
      
        <!-- Page Content -->
        <div class="content container-fluid">
          
        
        <employeereportheader />
          
        <!-- Content Starts -->
            
        <employeereportfilter />

          <div class="row">
            <div class="col-md-12">
              <div class="table-responsive">
                <employeereporttable />
              </div>
            </div>
          </div>
          
                
          <!-- /Content End -->
          
                </div>
        <!-- /Page Content -->
        
            </div>
      <!-- /Page Wrapper -->
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  export default {
    components: {
   
    },
    mounted() {


    },
    name: 'employeereports'
  }
</Script>