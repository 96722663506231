<template>
<div class="contact-cat col-sm-4 col-lg-3">
                          <a href="javascript:void(0)" class="btn btn-primary w-100" data-bs-toggle="modal" data-bs-target="#add_contact"><i class="fa fa-plus"></i> Add Contact</a>
                          <div class="roles-menu">
                            <ul>
                              <li class="active"><a href="javascript:void(0);">All</a></li>
                              <li><a href="javascript:void(0)">Company</a></li>
                              <li><a href="javascript:void(0)">Client</a></li>
                              <li><a href="javascript:void(0)">Staff</a></li>
                            </ul>
                          </div>
                        </div>
</template>