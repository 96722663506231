<template>
  <div class="employeedashboard">
     <div class="main-wrapper">
        <main-header></main-header>
        <sidebar></sidebar>
        <!-- Page Wrapper -->
        <div class="page-wrapper">
           <div class="content container-fluid">
              
              <div class="row">
                 <div class="col-lg-8">
                    <div class="row">
                      <welcomeheader />
                      <div class="col-xl-7">
                       <today />
                      </div>
                      <div class="col-xl-5 d-lg-none d-xl-block">
                        <div class="col-xl-12 col-lg-6">
                          <div class="dashboard-default">
                             <div class="card">
                                <div class="card-body pb-0">
                                   <div class="card-header pb-0 border-0">
                                      <div class="d-flex justify-content-between">
                                         <div class="flex-grow-1">
                                            <p class="square-after f-w-600 header-text-primary">Punch In at<i class="fa fa-circle"> </i></p>
                                            <h4>1 Mar 2023 10.00 AM</h4>
                                         </div>
                                      </div>
                                   </div>
                                   <div class="card-body">
                                      <div class="punch-status">
                                         <div class="punch-info">
                                            <div class="punch-hours"><span>3.45 hrs</span></div>
                                         </div>
                                         <div class="statistics">
                                            <div class="row">
                                               <div class="col-md-12 text-center">
                                                  <div class="stats-box">
                                                     <p>Shift Time</p>
                                                     <h6>11:00 PM-08:00 AM</h6>
                                                  </div>
                                               </div>
                                            </div>
                                         </div>
                                      </div>
                                   </div>
                                </div>
                             </div>
                          </div>
                       </div>
                       <div class="col-xl-12 col-lg-6">
                          <div class="dashboard-default recent-activity">
                             <div class="card">
                                <div class="card-body pb-0 log-height">
                                   <div class="card-header pb-0 border-0">
                                      <div class="d-flex justify-content-between">
                                         <div class="flex-grow-1">
                                            <p class="square-after f-w-600 header-text-primary">Today Activity<i class="fa fa-circle"> </i></p>
                                            <h4>11 Mar 2023</h4>
                                         </div>
                                      </div>
                                   </div>
                                   <div class="card-body">
                                      <ul class="res-activity-list dashboard-activity">
                                         <li>
                                            <p class="mb-0">Punch In at</p>
                                            <p class="res-activity-time"><i class="fa fa-clock-o"></i> 10.00 AM.</p>
                                         </li>
                                         <li>
                                            <p class="mb-0">Punch Out at</p>
                                            <p class="res-activity-time"><i class="fa fa-clock-o"></i> 11.00 AM.</p>
                                         </li>
                                         <li>
                                            <p class="mb-0">Punch In at</p>
                                            <p class="res-activity-time"><i class="fa fa-clock-o"></i> 11.15 AM.</p>
                                         </li>
                                         <li>
                                            <p class="mb-0">Punch Out at</p>
                                            <p class="res-activity-time"><i class="fa fa-clock-o"></i> 1.30 AM.</p>
                                         </li>
                                         <li>
                                            <p class="mb-0">Punch Out at</p>
                                            <p class="res-activity-time"><i class="fa fa-clock-o"></i> 1.30 AM.</p>
                                         </li>
                                         
                                      </ul>
                                   </div>
                                </div>
                             </div>
                          </div>
                       </div>
                      </div>
                    </div>
                 </div>
                 <div class="col-lg-4">
                    <reporting />
                    <myteam />
                  </div>
              </div>
           </div>
           <!-- /Page Content -->
        </div>
        <!-- /Page Wrapper -->
     </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  export default {
    components: {
   
    },
    mounted() {
  
    },
    name: 'home'
  }
</Script>