<template>
<!-- Plan Details -->
              <div class="row">
                <div class="col-md-12">
                  <div class="card card-table mb-0">
                    <div class="card-header">
                      <h4 class="card-title mb-0">Plan Transactions</h4>
                    </div>
                    <div class="card-body">
                      <div class="table-responsive">
                        <table class="table table-hover table-center mb-0">
                          <thead>
                            <tr>
                              <th>Plan</th>
                              <th>Users</th>
                              <th>Plan Duration</th>
                              <th>Start Date</th>
                              <th>End Date</th>
                              <th>Amount</th>
                              <th>Update Plan</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Free Trial</td>
                              <td>30 Users</td>
                              <td>1 Month</td>
                              <td>9 Nov 2019</td>
                              <td>8 Dec 2019</td>
                              <td>$200.00</td>
                              <td><a class="btn btn-primary btn-sm" href="javascript:void(0);">Change Plan</a></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Plan Details -->
</template>