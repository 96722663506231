<template>
<!-- Page Header -->
    <div class="page-header">
       <div class="row">
                                    <div class="col-sm-12">
                                        <h3 class="page-title">Basic Settings</h3>
                                    </div>
                                </div>
                            </div>
                            <!-- /Page Header -->
</template>