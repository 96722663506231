<template>
  <!-- Overtime Statistics -->
          <div class="row">
            <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <div class="stats-info">
                <h6>Overtime Employee</h6>
                <h4>12 <span>this month</span></h4>
              </div>
            </div>
            <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <div class="stats-info">
                <h6>Overtime Hours</h6>
                <h4>118 <span>this month</span></h4>
              </div>
            </div>
            <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <div class="stats-info">
                <h6>Pending Request</h6>
                <h4>23</h4>
              </div>
            </div>
            <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <div class="stats-info">
                <h6>Rejected</h6>
                <h4>5</h4>
              </div>
            </div>
          </div>
          <!-- /Overtime Statistics -->
</template>