<template>
<div class="row">
            <div class="col-lg-6">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title mb-0">Contextual Classes</h4>
                </div>
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table mb-0">
                      <thead>
                        <tr>
                          <th>First Name</th>
                          <th>Last Name</th>
                          <th>Email</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Default</td>
                          <td>Defaultson</td>
                          <td>def@somemail.com</td>
                        </tr>
                        <tr class="table-primary">
                          <td>Primary</td>
                          <td>Doe</td>
                          <td>john@example.com</td>
                        </tr>
                        <tr class="table-secondary">
                          <td>Secondary</td>
                          <td>Moe</td>
                          <td>mary@example.com</td>
                        </tr>
                        <tr class="table-success">
                          <td>Success</td>
                          <td>Dooley</td>
                          <td>july@example.com</td>
                        </tr>
                        <tr class="table-danger">
                          <td>Danger</td>
                          <td>Refs</td>
                          <td>bo@example.com</td>
                        </tr>
                        <tr class="table-warning">
                          <td>Warning</td>
                          <td>Activeson</td>
                          <td>act@example.com</td>
                        </tr>
                        <tr class="table-info">
                          <td>Info</td>
                          <td>Activeson</td>
                          <td>act@example.com</td>
                        </tr>
                        <tr class="table-light">
                          <td>Light</td>
                          <td>Activeson</td>
                          <td>act@example.com</td>
                        </tr>
                        <tr class="table-dark">
                          <td>Dark</td>
                          <td>Activeson</td>
                          <td>act@example.com</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title mb-0">Responsive Tables</h4>
                </div>
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>First Name</th>
                          <th>Last Name</th>
                          <th>Age</th>
                          <th>City</th>
                          <th>Country</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>Anna</td>
                          <td>Pitt</td>
                          <td>35</td>
                          <td>New York</td>
                          <td>USA</td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>Anna</td>
                          <td>Pitt</td>
                          <td>35</td>
                          <td>New York</td>
                          <td>USA</td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>Anna</td>
                          <td>Pitt</td>
                          <td>35</td>
                          <td>New York</td>
                          <td>USA</td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>Anna</td>
                          <td>Pitt</td>
                          <td>35</td>
                          <td>New York</td>
                          <td>USA</td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>Anna</td>
                          <td>Pitt</td>
                          <td>35</td>
                          <td>New York</td>
                          <td>USA</td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>Anna</td>
                          <td>Pitt</td>
                          <td>35</td>
                          <td>New York</td>
                          <td>USA</td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>Anna</td>
                          <td>Pitt</td>
                          <td>35</td>
                          <td>New York</td>
                          <td>USA</td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>Anna</td>
                          <td>Pitt</td>
                          <td>35</td>
                          <td>New York</td>
                          <td>USA</td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>Anna</td>
                          <td>Pitt</td>
                          <td>35</td>
                          <td>New York</td>
                          <td>USA</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
</template>