<template>
  <!-- Leave Statistics -->
          <div class="row">
            <div class="col-md-3">
              <div class="stats-info">
                <h6>Today Presents</h6>
                <h4>12 / 60</h4>
              </div>
            </div>
            <div class="col-md-3">
              <div class="stats-info">
                <h6>Planned Leaves</h6>
                <h4>8 <span>Today</span></h4>
              </div>
            </div>
            <div class="col-md-3">
              <div class="stats-info">
                <h6>Unplanned Leaves</h6>
                <h4>0 <span>Today</span></h4>
              </div>
            </div>
            <div class="col-md-3">
              <div class="stats-info">
                <h6>Pending Requests</h6>
                <h4>12</h4>
              </div>
            </div>
          </div>
          <!-- /Leave Statistics -->
</template>