<template>

  <div class="dashboard-default">
    <div class="card">
      <div class="card-body pb-0 team-height">
        <div class="card-header p-0 border-0">
            <div class="d-flex justify-content-between">
              <div class="flex-grow-1">
                  <p class="square-after f-w-600 header-text-primary">My Team<i class="fa fa-circle"> </i></p>
              </div>
            </div>
        </div>
        <div class="col-12 mt-3">
          <div class="row">
            <div class="col-md-6">
              <div class="content-box">
                <img alt="" src="https://smarthr-laravel-vuejs.dreamguystech.com/images/avatar-02.jpg">
                <div class="empolye-detail text-truncate">
                  <h3>John Dow</h3>
                  <p class="text-truncate">Software Engineer</p>
                </div>
              </div>  
            </div>
            <div class="col-md-6">
              <div class="content-box">
                <img alt="" src="https://smarthr-laravel-vuejs.dreamguystech.com/images/avatar-02.jpg">
                <div class="empolye-detail text-truncate">
                  <h3>John Dow</h3>
                  <p class="text-truncate">Software Engineer</p>
                </div>
              </div>  
            </div>
            <div class="col-md-6">
              <div class="content-box">
                <img alt="" src="https://smarthr-laravel-vuejs.dreamguystech.com/images/avatar-02.jpg">
                <div class="empolye-detail text-truncate">
                  <h3>John Dow</h3>
                  <p class="text-truncate">Software Engineer</p>
                </div>
              </div>  
            </div>
            <div class="col-md-6">
              <div class="content-box">
                <img alt="" src="https://smarthr-laravel-vuejs.dreamguystech.com/images/avatar-02.jpg">
                <div class="empolye-detail text-truncate">
                  <h3>John Dow</h3>
                  <p class="text-truncate">Software Engineer</p>
                </div>
              </div>  
            </div>
            <div class="col-md-6">
              <div class="content-box">
                <img alt="" src="https://smarthr-laravel-vuejs.dreamguystech.com/images/avatar-02.jpg">
                <div class="empolye-detail text-truncate">
                  <h3>John Dow</h3>
                  <p class="text-truncate">Software Engineer</p>
                </div>
              </div>  
            </div>
            <div class="col-md-6">
              <div class="content-box">
                <img alt="" src="https://smarthr-laravel-vuejs.dreamguystech.com/images/avatar-02.jpg">
                <div class="empolye-detail text-truncate">
                  <h3>John Dow</h3>
                  <p class="text-truncate">Software Engineer</p>
                </div>
              </div>  
            </div>
            <div class="welcome-content text-center mt-3 mb-3">
               <router-link to="team-list" class="cta-btn">View More</router-link>
             </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
  import contact from '../../../../assets/json/contact.json';
  const images = require.context('../../../../assets/img/profiles', false, /\.png$|\.jpg$/)
    import Vue from 'vue'
    export default {
      data() {
      return {
        contact: contact
      }
    },
      components: {
      },
      methods: {
          loadImg(imgPath) {
              return images('./' + imgPath).default
      },
      },
    }
</script>