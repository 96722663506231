<template>
  
            <div class="col-md-3">
              <div class="card">
                <div class="card-body">
                <ul class="nav nav-tabs nav-tabs-solid nav-justified flex-column">
                  <li class="nav-item"><a class="nav-link active" data-bs-toggle="tab" href="#home">Apptitude</a></li>
                  <li class="nav-item"><a class="nav-link" data-bs-toggle="tab" href="#menu1">Schedule Interview</a></li>
                </ul>
              </div>
            </div>
          </div>
 
</template>