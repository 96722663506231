<template>
  <div class="attendanceemployee">
    <div class="main-wrapper">
       <main-header></main-header>
      <sidebar></sidebar>
       <!-- Page Wrapper -->
        <div class="page-wrapper">
        <div class="content container-fluid">
        
          <div class="page-header">
            <div class="row">
              <div class="col-sm-12">
                <h3 class="page-title">Attendance</h3>
                <ul class="breadcrumb">
                  <li class="breadcrumb-item"><router-link to="/index">Dashboard</router-link></li>
                  <li class="breadcrumb-item active">My Attendance</li>
                  <li class="breadcrumb-item active">Report</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="row">
          <timesheet />
          <statistics1 />
          <todayactivity />
          </div>
          
           <div class="row">
          <div class="col-lg-12">
            <div class="card">
              <div class="card-body">
                <attendempfilter />
                <div class="table-responsive">
                <table class="table table-striped custom-table mb-0">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>	Punched In</th>
                      <th>Punched Out</th>
                      <th>Behavior</th>
                      <th>Working Hours</th>
                      <th>Effective Hours</th>
                      
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Monday 26 Dec 2022</td>
                      <td>05:01 AM</td>
                      <td>12:57 PM</td>
                      <td>
                        <a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);">
                          <i class="fa fa-dot-circle-o text-purple"></i> Early in</a>
                      </td>
                      <td>07:55</td>
                      <td>17:59 (early)</td>
                      
                    </tr>
                    <tr>
                      <td>Monday 27 Dec 2022</td>
                      <td>05:01 AM</td>
                      <td>12:57 PM</td>
                      <td>
                        <a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);">
                          <i class="fa fa-dot-circle-o text-danger"></i> Late in</a>
                      </td>
                      <td>07:55</td>
                      <td>17:59 (early)</td>
                      
                    </tr>
                  </tbody>
                </table>
              </div>
              </div>
            </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  export default {

    name: 'attendanceemployee'
  }
</Script>