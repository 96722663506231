<template>
 <form>
                                <div class="form-group">
                                    <label>ApiKey <span class="text-danger">*</span></label>
                                    <input type="text" class="form-control" value="xxxxxxxx" readonly="">
                                </div>
                                <div class="form-group">
                                    <label>ApiSecret <span class="text-danger">*</span></label>
                                    <input type="text" class="form-control" value="xxxxxxxxxxxxxxxxxxxxxxxxxx" readonly="">
                                </div>
                                
                                <div class="submit-section">
                                    <button class="btn btn-primary submit-btn">Save Changes</button>
                                </div>
                            </form>
</template>