<template>
  <div class="holidays">
     <div class="main-wrapper">
        <main-header></main-header>
        <sidebar></sidebar>
        <!-- Page Wrapper -->
        <div class="page-wrapper">
           <!-- Page Content -->
           <div class="content container-fluid">
            <div class="page-header">
            <div class="row">
                    <div class="col-sm-12">
                      <h3 class="page-title">Profile</h3>
                      <ul class="breadcrumb">
                          <li class="breadcrumb-item">
                            <router-link to="/index">Dashboard</router-link>
                          </li>
                          <li class="breadcrumb-item active">My Profile</li>
                          <li class="breadcrumb-item active">Appraisals</li>
                      </ul>
                    </div>
                </div>
              </div>
              <div class="row">
                 <div class="col-md-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="table-responsive">
                       <table class="table table-striped custom-table mb-0">
                          <thead>
                             <tr>
                                <th>Date</th>
                                <th>Salary </th>
                                <th>Designation</th>
                                <th>Vehicle</th>
                             </tr>
                          </thead>
                          <tbody>
                             <tr class="holiday-completed" v-for="item in appraisals" :key="item.id">
                                <td>{{item.date}}</td>
                                <td class="primaryColor">{{item.salary}}</td>
                                <td class="primaryColor">{{item.designation}}</td>
                                <td class="primaryColor">{{item.vehicle}}</td>
                             </tr>
                          </tbody>
                       </table>
                    </div>
                    </div>
                  </div>
                    
                 </div>
              </div>
           </div>
           <!-- /Page Content -->
        </div>
        <!-- /Page Wrapper -->
     </div>
  </div>
</template>
<script>
  import appraisals from '../../../assets/json/appraisals.json';
    import Vue from 'vue'
    export default {
      data() {
      return {
        appraisals: appraisals
      }
      },
      components: {
      }
    }
</script>