<template>
  <div class="row">
            <div class="col-md-6">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title mb-0">Basic Form</h4>
                </div>
                <div class="card-body">
                  <form action="#">
                    <div class="form-group">
                      <label>First Name</label>
                      <input type="text" class="form-control">
                    </div>
                    <div class="form-group">
                      <label>Last Name</label>
                      <input type="text" class="form-control">
                    </div>
                    <div class="form-group">
                      <label>Email Address</label>
                      <input type="email" class="form-control">
                    </div>
                    <div class="form-group">
                      <label>Username</label>
                      <input type="text" class="form-control">
                    </div>
                    <div class="form-group">
                      <label>Password</label>
                      <input type="password" class="form-control">
                    </div>
                    <div class="form-group">
                      <label>Repeat Password</label>
                      <input type="password" class="form-control">
                    </div>
                    <div class="text-end">
                      <button type="submit" class="btn btn-primary">Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title mb-0">Address Form</h4>
                </div>
                <div class="card-body">
                  <form action="#">
                    <div class="form-group">
                      <label>Address Line 1</label>
                      <input type="text" class="form-control">
                    </div>
                    <div class="form-group">
                      <label>Address Line 2</label>
                      <input type="text" class="form-control">
                    </div>
                    <div class="form-group">
                      <label>City</label>
                      <input type="text" class="form-control">
                    </div>
                    <div class="form-group">
                      <label>State</label>
                      <input type="text" class="form-control">
                    </div>
                    <div class="form-group">
                      <label>Country</label>
                      <input type="text" class="form-control">
                    </div>
                    <div class="form-group">
                      <label>Postal Code</label>
                      <input type="text" class="form-control">
                    </div>
                    <div class="text-end">
                      <button type="submit" class="btn btn-primary">Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
</template>