<template>
<div class="card tab-box">
            <div class="row user-tabs">
              <div class="col-lg-12 col-md-12 col-sm-12 line-tabs">
                <ul class="nav nav-tabs nav-tabs-bottom">
                  <li class="nav-item col-sm-3"><a class="nav-link active" data-bs-toggle="tab" href="#myprojects">Projects</a></li>
                  <li class="nav-item col-sm-3"><a class="nav-link" data-bs-toggle="tab" href="#tasks">Tasks</a></li>
                </ul>
              </div>
            </div>
          </div>
</template>