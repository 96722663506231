<template>
<div class="row">
            <div class="col-md-12">
              <div class="table-responsive">
                <table class="table table-striped custom-table datatable" id="scheduletable">
                  <thead>
                    <tr>
                      <th>Scheduled Shift</th>
                      <th>Fri 21</th>
                      <th>Sat 22</th>
                      <th>Sun 23</th>
                      <th>Mon 24</th>
                                            <th>Tue 25</th>
                                            <th>Wed 26</th>
                                            <th>Thu 27</th>
                                            <th>Fri 28</th>
                                            <th>Sat 29</th>
                    </tr>
                  </thead>
                  <tbody>
                                        <tr>
                      <td>
                        <h2 class="table-avatar">
                          <router-link to="/profile" class="avatar"><img alt="" src="../../../../assets/img/profiles/avatar-02.jpg"></router-link>
                          <router-link to="/profile">John Doe <span>Web Designer</span></router-link>
                        </h2>
                      </td>
                      <td>
                                                <div class="user-add-shedule-list">
                                                    <h2>
                                                        <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#edit_schedule" style="border:2px dashed #1eb53a">
                                                        <span class="username-info m-b-10">6:30 am - 9:30 pm ( 14 hrs 15 mins)</span>
                                                        <span class="userrole-info">Web Designer - SMARTHR</span>
                                                        </a>
                                                    </h2>
                                                </div>
                                            </td>
                      <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                      <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                      <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                      <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                      <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="user-add-shedule-list">
                                                    <h2>
                                                        <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#edit_schedule" style="border:2px dashed #1eb53a">
                                                        <span class="username-info m-b-10">6:30 am - 9:30 pm ( 14 hrs 15 mins)</span>
                                                        <span class="userrole-info">Web Designer - SMARTHR</span>
                                                        </a>
                                                    </h2>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                    </tr>
                    <tr>
                      <td>
                        <h2 class="table-avatar">
                          <router-link to="/profile" class="avatar"><img alt="" src="../../../../assets/img/profiles/avatar-09.jpg"></router-link>
                          <router-link to="/profile">Richard Miles <span>Web Developer</span></router-link>
                        </h2>
                      </td>
                      
                      <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="user-add-shedule-list">
                                                    <h2>
                                                        <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#edit_schedule" style="border:2px dashed #1eb53a">
                                                        <span class="username-info m-b-10">6:30 am - 9:30 pm ( 14 hrs 15 mins)</span>
                                                        <span class="userrole-info">Web Designer - SMARTHR</span>
                                                        </a>
                                                    </h2>
                                                </div>
                                            </td>
                      <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                      <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                      <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                      <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                                          
                                            <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="user-add-shedule-list">
                                                    <h2>
                                                        <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#edit_schedule" style="border:2px dashed #1eb53a">
                                                        <span class="username-info m-b-10">6:30 am - 9:30 pm ( 14 hrs 15 mins)</span>
                                                        <span class="userrole-info">Web Designer - SMARTHR</span>
                                                        </a>
                                                    </h2>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="user-add-shedule-list">
                                                    <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#add_schedule">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    </a>
                                                </div>
                                            </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
</template>
<script>
import util from '../../../../assets/utils/util'
  import Vue from 'vue'
  export default {
    data() {
    return {
      
    }
  },
    components: {
    },
    mounted() {
    util.datatable('#scheduletable')
    },
  }
</Script>