<template>
<!-- Page Header -->
      <div class="page-header">
            <div class="row">
              <div class="col-sm-12">
                <h3 class="page-title">Form Validation</h3>
                <ul class="breadcrumb">
                  <li class="breadcrumb-item"><router-link to="/index">Dashboard</router-link></li>
                  <li class="breadcrumb-item active">Form Validation</li>
                </ul>
              </div>
            </div>
      </div>
      <!-- /Page Header -->
</template>
