<template>
    <div class="filter-section grid-filter">
      <div class="row">
        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-3 col-12 pe-sm-0"> 
          <div class="form-group form-focus select-focus">
            <vue-select :options="addattenemployeemonth" />
            <label class="focus-label">Select Month</label>
          </div>
        </div>
        <div class="col-sm-2 ps-sm-0">  
          <div>
            <a href="javascript:void(0)" class="btn btnPrimary w-100 text-white"> <i class="las la-search"></i> </a>   
          </div>
        </div>     
      </div>
    </div>
</template>
<script>
  import Vue from 'vue'
  import { ref } from 'vue'
  const currentDate = ref(new Date())
  export default {
     data() {
    return {
      addattenemployeemonth: ["-", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      addattenemployeeyear: ["-", "2019", "2018", "2017", "2016", "2015"],
      startdate: currentDate,
    }
    },
    components: {
   
    },
    mounted() {
    }
  }
</Script>