<template>
  <div class="formmask">
    <div class="main-wrapper">
      <main-header></main-header>
      <sidebar></sidebar>
       <!-- Page Wrapper -->
       <div class="page-wrapper">
       <div class="content container-fluid">
        
       <headerformmask />
          
       <formmaskcontent />
        
        </div>      
      </div>
      <!-- /Page Wrapper -->
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  export default {
    components: {
   
    },
    mounted() {
   

    },
    name: 'formmask'
  }
</Script>