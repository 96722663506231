<template>
  <!-- Page Header -->
          <div class="page-header">
            <div class="row align-items-center">
              <div class="col">
                <h3 class="page-title">Payroll Items</h3>
                <ul class="breadcrumb">
                  <li class="breadcrumb-item"><router-link to="/index">Dashboard</router-link></li>
                  <li class="breadcrumb-item active">Payroll Items</li>
                </ul>
              </div>
            </div>
          </div>
          <!-- /Page Header -->
</template>