<template>
<div class="card">
                <div class="card-header">
                  <h4 class="card-title mb-0">Sizing</h4>
                </div>
                <div class="card-body">
                  <form action="#">
                    <div class="form-group row">
                      <label class="col-form-label col-lg-2">Input Group Large</label>
                      <div class="col-lg-10">
                        <div class="input-group input-group-lg">
                          <span class="input-group-text" id="sizing-addon1">@</span>
                          <input type="text" class="form-control" placeholder="Username" aria-describedby="sizing-addon1">
                        </div>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-form-label col-lg-2">Input Group Default</label>
                      <div class="col-lg-10">
                        <div class="input-group">
                          <span class="input-group-text" id="sizing-addon2">@</span>
                          <input type="text" class="form-control" placeholder="Username" aria-describedby="sizing-addon2">
                        </div>
                      </div>
                    </div>
                    <div class="form-group row mb-0">
                      <label class="col-form-label col-lg-2">Input Group Small</label>
                      <div class="col-lg-10">
                        <div class="input-group input-group-sm">
                          <span class="input-group-text" id="sizing-addon3">@</span>
                          <input type="text" class="form-control" placeholder="Username" aria-describedby="sizing-addon3">
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
</template>