<template>
  <section class="review-section information">
            <div class="review-header text-center">
              <h3 class="review-title">Employee Basic Information</h3>
              <p class="text-muted">Lorem ipsum dollar</p>
            </div>
            <div class="row">
              <div class="col-md-12 col-sm-12">
                <div class="table-responsive">
                  <table class="table table-bordered table-nowrap review-table mb-0">
                    <tbody>
                      <tr>
                        <td>
                          <form>
                            <div class="form-group">
                              <label for="name">Name</label>
                              <input type="text" class="form-control" id="name">
                            </div>
                            <div class="form-group">
                              <label for="depart3">Department</label>
                              <input type="text" class="form-control" id="depart3">
                            </div>
                            <div class="form-group">
                              <label for="departa">Designation</label>
                              <input type="text" class="form-control" id="departa">
                            </div>
                            <div class="form-group">
                              <label for="qualif">Qualification: </label>
                              <input type="text" class="form-control" id="qualif">
                            </div>
                          </form>
                        </td>
                        <td>
                          <form>
                            <div class="form-group">
                              <label for="doj">Emp ID</label>
                              <input type="text" class="form-control" value="DGT-009">
                            </div>
                            <div class="form-group">
                              <label for="doj">Date of Join</label>
                              <input type="text" class="form-control" id="doj">
                            </div>
                            <div class="form-group">
                              <label for="doc">Date of Confirmation</label>
                              <input type="text" class="form-control" id="doc">
                            </div>
                            <div class="form-group">
                              <label for="qualif1">Previous years of Exp</label>
                              <input type="text" class="form-control" id="qualif1">
                            </div>
                          </form>
                        </td>
                        <td>
                          <form>
                            <div class="form-group">
                              <label for="name1"> RO's Name</label>
                              <input type="text" class="form-control" id="name1">
                            </div>
                            <div class="form-group">
                              <label for="depart1"> RO Designation: </label>
                              <input type="text" class="form-control" id="depart1">
                            </div>
                          </form>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>   
          
          <section class="review-section professional-excellence">
            <div class="review-header text-center">
              <h3 class="review-title">Professional Excellence</h3>
              <p class="text-muted">Lorem ipsum dollar</p>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="table-responsive">
                  <table class="table table-bordered review-table mb-0">
                    <thead>
                      <tr>
                        <th style="width:40px;">#</th>
                        <th>Key Result Area</th>
                        <th>Key Performance Indicators</th>
                        <th>Weightage</th>
                        <th>Percentage achieved <br>( self Score )</th>
                        <th>Points Scored <br>( self )</th>
                        <th>Percentage achieved <br>( RO's Score )</th>
                        <th>Points Scored <br>( RO )</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td rowspan="2">1</td>
                        <td rowspan="2">Production</td>
                        <td>Quality</td>
                        <td><input type="text" class="form-control" readonly value="30"></td>
                        <td><input type="text" class="form-control" ></td>
                        <td><input type="text" class="form-control" readonly value="0"></td>
                        <td><input type="text" class="form-control" ></td>
                        <td><input type="text" class="form-control" readonly value="0"></td>
                      </tr>
                      <tr>
                        <td>TAT (turn around time)</td>
                        <td><input type="text" class="form-control" readonly value="30"></td>
                        <td><input type="text" class="form-control" ></td>
                        <td><input type="text" class="form-control" readonly value="0"></td>
                        <td><input type="text" class="form-control" ></td>
                        <td><input type="text" class="form-control" readonly value="0"></td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>Process Improvement</td>
                        <td>PMS,New Ideas</td>
                        <td><input type="text" class="form-control" readonly value="10"></td>
                        <td><input type="text" class="form-control" ></td>
                        <td><input type="text" class="form-control" readonly value="0"></td>
                        <td><input type="text" class="form-control" ></td>
                        <td><input type="text" class="form-control" readonly value="0"></td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>Team Management</td>
                        <td>Team Productivity,dynaics,attendance,attrition</td>
                        <td><input type="text" class="form-control" readonly value="5"></td>
                        <td><input type="text" class="form-control" ></td>
                        <td><input type="text" class="form-control" readonly value="0"></td>
                        <td><input type="text" class="form-control" ></td>
                        <td><input type="text" class="form-control" readonly value="0"></td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>Knowledge Sharing</td>
                        <td>Sharing the knowledge for team productivity </td>
                        <td><input type="text" class="form-control" readonly value="5"></td>
                        <td><input type="text" class="form-control" ></td>
                        <td><input type="text" class="form-control" readonly value="0"></td>
                        <td><input type="text" class="form-control" ></td>
                        <td><input type="text" class="form-control" readonly value="0"></td>
                      </tr>
                      <tr>
                        <td>5</td>
                        <td>Reporting and Communication</td>
                        <td>Emails/Calls/Reports and Other Communication</td>
                        <td><input type="text" class="form-control" readonly value="5"></td>
                        <td><input type="text" class="form-control" ></td>
                        <td><input type="text" class="form-control" readonly value="0"></td>
                        <td><input type="text" class="form-control" ></td>
                        <td><input type="text" class="form-control" readonly value="0"></td>
                      </tr>
                      <tr>
                        <td colspan="3" class="text-center">Total </td>
                        <td><input type="text" class="form-control" readonly value="85"></td>
                        <td><input type="text" class="form-control" readonly value="0"></td>
                        <td><input type="text" class="form-control" readonly value="0"></td>
                        <td><input type="text" class="form-control" readonly value="0"></td>
                        <td><input type="text" class="form-control" readonly value="0"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>
          <section class="review-section personal-excellence">
            <div class="review-header text-center">
              <h3 class="review-title">Personal Excellence</h3>
              <p class="text-muted">Lorem ipsum dollar</p>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="table-responsive">
                  <table class="table table-bordered review-table mb-0">
                    <thead>
                      <tr>
                        <th style="width:40px;">#</th>
                        <th>Personal Attributes</th>
                        <th>Key Indicators</th>
                        <th>Weightage</th>
                        <th>Percentage achieved <br>( self Score )</th>
                        <th>Points Scored <br>( self )</th>
                        <th>Percentage achieved <br>( RO's Score )</th>
                        <th>Points Scored <br>( RO )</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td rowspan="2">1</td>
                        <td rowspan="2">Attendance</td>
                        <td>Planned or Unplanned Leaves</td>
                        <td><input type="text" class="form-control" readonly value="2"></td>
                        <td><input type="text" class="form-control" ></td>
                        <td><input type="text" class="form-control" readonly value="0"></td>
                        <td><input type="text" class="form-control" ></td>
                        <td><input type="text" class="form-control" readonly value="0"></td>
                      </tr>
                      <tr>
                        <td>Time Consciousness</td>
                        <td><input type="text" class="form-control" readonly value="2"></td>
                        <td><input type="text" class="form-control" ></td>
                        <td><input type="text" class="form-control" readonly value="0"></td>
                        <td><input type="text" class="form-control" ></td>
                        <td><input type="text" class="form-control" readonly value="0"></td>
                      </tr>
                      <tr>
                        <td rowspan="2">2</td>
                        <td rowspan="2">Attitude & Behavior</td>
                        <td>Team Collaboration</td>
                        <td><input type="text" class="form-control" readonly value="2"></td>
                        <td><input type="text" class="form-control" ></td>
                        <td><input type="text" class="form-control" readonly value="0"></td>
                        <td><input type="text" class="form-control" ></td>
                        <td><input type="text" class="form-control" readonly value="0"></td>
                      </tr>
                      <tr>
                        <td>Professionalism & Responsiveness</td>
                        <td><input type="text" class="form-control" readonly value="2"></td>
                        <td><input type="text" class="form-control" ></td>
                        <td><input type="text" class="form-control" readonly value="0"></td>
                        <td><input type="text" class="form-control" ></td>
                        <td><input type="text" class="form-control" readonly value="0"></td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>Policy & Procedures </td>
                        <td>Adherence to policies and procedures</td>
                        <td><input type="text" class="form-control" readonly value="2"></td>
                        <td><input type="text" class="form-control" ></td>
                        <td><input type="text" class="form-control" readonly value="0"></td>
                        <td><input type="text" class="form-control" ></td>
                        <td><input type="text" class="form-control" readonly value="0"></td>
                      </tr>
                      <tr>
                      <td>4</td>
                        <td>Initiatives</td>
                        <td>Special Efforts, Suggestions,Ideas,etc.</td>
                        <td><input type="text" class="form-control" readonly value="2"></td>
                        <td><input type="text" class="form-control" ></td>
                        <td><input type="text" class="form-control" readonly value="0"></td>
                        <td><input type="text" class="form-control" ></td>
                        <td><input type="text" class="form-control" readonly value="0"></td>
                      </tr>
                      <tr>
                        <td>5</td>
                        <td>Continuous Skill Improvement</td>
                        <td>Preparedness to move to next level & Training utilization</td>
                        <td><input type="text" class="form-control" readonly value="3"></td>
                        <td><input type="text" class="form-control" ></td>
                        <td><input type="text" class="form-control" readonly value="0"></td>
                        <td><input type="text" class="form-control" ></td>
                        <td><input type="text" class="form-control" readonly value="0"></td>
                      </tr>
                      <tr>
                        <td colspan="3" class="text-center">Total </td>
                        <td><input type="text" class="form-control" readonly value="15"></td>
                        <td><input type="text" class="form-control" readonly value="0"></td>
                        <td><input type="text" class="form-control" readonly value="0"></td>
                        <td><input type="text" class="form-control" readonly value="0"></td>
                        <td><input type="text" class="form-control" readonly value="0"></td>
                      </tr>
                      <tr>
                        <td colspan="3" class="text-center"><b>Total Percentage(%)</b></td>
                        <td colspan="5" class="text-center"><input type="text" class="form-control" readonly value="0"></td>
                      </tr>
                      <tr>
                        <td colspan="8" class="text-center">
                          <div class="grade-span">
                            <h4>Grade</h4>
                            <span class="badge bg-inverse-danger">Below 65 Poor</span> 
                            <span class="badge bg-inverse-warning">65-74 Average</span> 
                            <span class="badge bg-inverse-info">75-84 Satisfactory</span> 
                            <span class="badge bg-inverse-purple">85-92 Good</span> 
                            <span class="badge bg-inverse-success">Above 92 Excellent</span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>
</template>