<template>
<div class="col-xl-6 d-flex">
              <div class="card flex-fill">
                <div class="card-header">
                  <h4 class="card-title mb-0">Basic Form</h4>
                </div>
                <div class="card-body">
                  <form action="#">
                    <div class="form-group row">
                      <label class="col-lg-3 col-form-label">First Name</label>
                      <div class="col-lg-9">
                        <input type="text" class="form-control">
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-lg-3 col-form-label">Last Name</label>
                      <div class="col-lg-9">
                        <input type="text" class="form-control">
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-lg-3 col-form-label">Email Address</label>
                      <div class="col-lg-9">
                        <input type="email" class="form-control">
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-lg-3 col-form-label">Username</label>
                      <div class="col-lg-9">
                        <input type="text" class="form-control">
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-lg-3 col-form-label">Password</label>
                      <div class="col-lg-9">
                        <input type="password" class="form-control">
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-lg-3 col-form-label">Repeat Password</label>
                      <div class="col-lg-9">
                        <input type="password" class="form-control">
                      </div>
                    </div>
                    <div class="text-end">
                      <button type="submit" class="btn btn-primary">Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
</template>