<template>

  <div class="policies">

    <div class="main-wrapper">

      <main-header></main-header>

      <sidebar></sidebar>
      
       <!-- Page Wrapper -->
       <div class="page-wrapper">
      
        <!-- Page Content -->
        <div class="content container-fluid">
        
        <headerpolicies />
          
        <policiestable />

        </div>
        <!-- /Page Content -->

        <modalpolicies />
      
      </div>
      <!-- /Page Wrapper -->
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  export default {
    components: {
   
    },
    mounted() {

    },
    name: 'policies'
  }
</Script>