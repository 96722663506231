<template>
<!-- Page Header -->
                    <div class="page-header">
                        <div class="row align-items-center">
                            <div class="col">
                                <h3 class="page-title">Categories</h3>
                                <ul class="breadcrumb">
                                    <li class="breadcrumb-item"><router-link to="/index">Dashboard</router-link></li>
                                    <li class="breadcrumb-item active">Accounts</li>
                                </ul>
                            </div>
                            <div class="col-auto float-end ms-auto">
                                <a href="javascript:void(0)" class="btn add-btn" data-bs-toggle="modal" data-bs-target="#add_categories"><i class="fa fa-plus"></i> Add Categories</a>
                            </div>
                        </div>
                    </div>
<!-- /Page Header -->
</template>