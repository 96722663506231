<template>
 <!-- Page Header -->
          <div class="page-header">
            <div class="row align-items-center">
              <div class="col">
                <h3 class="page-title">View Message</h3>
                <ul class="breadcrumb">
                  <li class="breadcrumb-item"><router-link to="/index">Dashboard</router-link></li>
                  <li class="breadcrumb-item active">View Message</li>
                </ul>
              </div>
              <div class="col-auto float-end ms-auto">
                <router-link to="/compose" class="btn add-btn"><i class="fa fa-plus"></i> Compose</router-link>
              </div>
            </div>
          </div>
          <!-- /Page Header -->
</template>