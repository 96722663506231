<template>
<div class="page-header">
    <div class="row">
    <div class="col-sm-12">
    <h3 class="page-title">Welcome Admin!</h3>
    <ul class="breadcrumb">
    <li class="breadcrumb-item active">Dashboard</li>
    </ul>
    </div>
    </div>
</div>
</template>