 <template>
 <li class="nav-item dropdown has-arrow main-drop custom-drop">
            <a href="javascript:void(0)" class="dropdown-toggle nav-link" data-bs-toggle="dropdown">
              <span class="user-img me-1"><img src="http://portal.abtach.com/uploads/employee/pictures/165520251962a862d79e30d.jpg" alt="">
              <span class="status online"></span></span>
              <!-- <span class="adminclass">Admin</span> -->
            </a>
            <div class="dropdown-menu">
              <div class="user-dropdown">
                <div class="content-box">
                  <img alt="" src="http://portal.abtach.com/uploads/employee/pictures/165520251962a862d79e30d.jpg">
                  <div class="empolye-detail text-truncate"><h3>Ali Hassan Khan</h3>
                  <p class="text-truncate">Sr. Executive Vice President - SEVP</p>
                </div>
              </div>
              </div>
              <div class="dropdown-links">
                <router-link class="dropdown-item" to="/profile"><i class="la la-user"></i>My Profile</router-link>
                <router-link class="dropdown-item mb-0" to="/faqs"><i class="las la-question-circle"></i>FAQ</router-link>
              </div>
              <div class="dropdown-links last-child">
                <router-link class="dropdown-item" to="/login"><i class="las la-sign-out-alt"></i>Logout</router-link>
              </div>
            </div>
          </li>
</template>