<template>
<div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-body">
                  <div class="filter-section grid-filter">
                        <div class="row">
                           <div class="col-md-3 col-lg-3 col-xl-3 col-12 pe-sm-0">
                              <div class="form-group">
                                <input type="date" class="form-control border-end-0 rounded-s-0">
                              </div>
                           </div>
                           <div class="col-md-2 ps-sm-0">  
                              <div>
                                <a href="javascript:void(0)" class="btn btnPrimary w-100 text-white rounded-1"><i class="las la-search"></i></a>
                              </div>
                           </div>
                        </div>
                     </div>
                  <div class="row">
                    <div class="col-12">
                      <h4 class="mt-4 mb-4 text-center">Payslip - December 2022</h4>
                    </div>
                    <div class="col-sm-6">
                      <div>
                        <table class="table custom-table">
                          <tbody>
                            <tr>
                              <th><strong>Employee No.</strong></th>
                              <td><span class="float-end">1145</span></td>
                            </tr>
                            <tr>
                              <th><strong>CNIC #</strong></th>
                              <td><span class="float-end">35201-6830038-9</span></td>
                            </tr>
                            <tr>
                              <th><strong>Designation</strong></th>
                              <td><span class="float-end">Sr. Executive Vice President - SEVP</span></td>
                            </tr>
                            <tr>
                              <th><strong>Total Days</strong></th>
                              <td><span class="float-end">475</span></td>
                            </tr>
                            <tr>
                              <th><strong>Total Days</strong></th>
                              <td><span class="float-end">31</span></td>
                            </tr>
                            <tr>
                              <th><strong>Per Day Salary</strong></th>
                              <td><span class="float-end">27,419</span></td>
                            </tr>
                            <tr>
                              <th><strong>Bank</strong></th>
                              <td><span class="float-end">United Bank Limited</span></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div>
                        <table class="table custom-table">
                          <tbody>
                            <tr>
                              <th><strong>Employee Name.</strong></th>
                              <td><span class="float-end">Ali Hassan Khan</span></td>
                            </tr>
                            <tr>
                              <th><strong>Appointment Date</strong></th>
                              <td><span class="float-end">08-02-2016</span></td>
                            </tr>
                            <tr>
                              <th><strong>Department</strong></th>
                              <td><span class="float-end">Management Committee</span></td>
                            </tr>
                            <tr>
                              <th><strong>Earning Days</strong></th>
                              <td><span class="float-end">31</span></td>
                            </tr>
                            <tr>
                              <th><strong>Pay Through</strong></th>
                              <td><span class="float-end">	PK35UNIL0109000239634294</span></td>
                            </tr>
                            <tr>
                              <th><strong>Branch</strong></th>
                              <td><span class="float-end">	Stock Exchange Karachi</span></td>
                            </tr>
                            <tr>
                              <th><strong>Bank</strong></th>
                              <td><span class="float-end">United Bank Limited</span></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-5">
                    <div class="col-sm-6">
                      <div>
                        <table class="table custom-table">
                          <tbody>
                            <tr>
                              <th><strong>Title</strong></th>
                              <th><strong><span class="float-end">Actual</span></strong></th>
                              <th><strong><span class="float-end">Earning</span></strong></th>
                            </tr>
                            <tr>
                              <th><strong>Basic Salary</strong></th>
                              <td><span class="float-end">547,888</span></td>
                              <td><span class="float-end">547,888</span></td>
                            </tr>
                            <tr>
                              <th><strong>House Rent</strong></th>
                              <td><span class="float-end">246,549</span></td>
                              <td><span class="float-end">246,549</span></td>
                            </tr>
                            <tr>
                              <th><strong>Medical</strong></th>
                              <td><span class="float-end">54,788</span></td>
                              <td><span class="float-end">54,788</span></td>
                            </tr>
                            <tr>
                              <th><strong>Cost Of Living Allowance</strong></th>
                              <td><span class="float-end">475</span></td>
                              <td><span class="float-end">475</span></td>
                            </tr>
                            <tr>
                              <th><strong>Special</strong></th>
                              <td><span class="float-end">300</span></td>
                              <td><span class="float-end">300</span></td>
                            </tr>
                            <tr>
                              <th><strong>Car Allowance</strong></th>
                              <td><span class="float-end">175,000</span></td>
                              <td><span class="float-end">175,000</span></td>
                            </tr>
                            <tr>
                              <th><strong>Arrears</strong></th>
                              <td><span class="float-end">0</span></td>
                              <td><span class="float-end">0</span></td>
                            </tr>
                            <tr>
                              <th><strong>Extra Days Amount</strong></th>
                              <td><span class="float-end">0</span></td>
                              <td><span class="float-end">0</span></td>
                            </tr>
                            <tr>
                              <th><strong>Total Earnings</strong></th>
                              <td><span class="float-end">1,025,000</span></td>
                              <td><span class="float-end">1,025,000</span></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div>
                        <table class="table custom-table">
                          <tbody>
                            <tr>
                              <th><strong>Title</strong></th>
                              <th><strong><span class="float-end">Amount</span></strong></th>
                            </tr>
                            <tr>
                              <th><strong>Absent Days Amount</strong> </th>
                              <td><span class="float-end">0</span></td>
                            </tr>
                            <tr>
                              <th><strong>Half Days Amount</strong></th>
                              <td><span class="float-end">0</span></td>
                            </tr>
                            <tr>
                              <th><strong>Late In + Early Out Amount</strong></th>
                              <td><span class="float-end">0</span></td>
                            </tr>
                            <tr>
                              <th><strong>Income Tax (will be calculated at the time of salary)</strong></th>
                              <td><span class="float-end">0</span></td>
                            </tr>
                            <tr>
                              <th><strong>EOBI</strong></th>
                              <td><span class="float-end">0</span></td>
                            </tr>
                            <tr>
                              <th><strong>Loan Installment</strong></th>
                              <td><span class="float-end">0</span></td>
                            </tr>
                            <tr>
                              <th><strong>NET SALARY</strong> </th>
                              <td><span class="float-end"><strong>1,025,000</strong></span></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
</template>