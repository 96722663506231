<template>
<div class="card mb-0">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="profile-view">
                    <div class="profile-img-wrap">
                      <div class="profile-img">
                        <a href="javascript:void(0)"><img alt="" src="../../../assets/img/profiles/avatar-02.jpg"></a>
                      </div>
                    </div>
                    <div class="profile-basic">
                      <div class="row">
                        <div class="col-md-5">
                          <div class="profile-info-left">
                            <h3 class="user-name m-t-0 mb-0">Ali Hassan Khan</h3>
                            <h6 class="text-muted">Sr. Executive Vice President - SEVP</h6>
                            <small class="text-muted">AliHassan.Khan@abtach.org</small>
                            <div class="staff-id">Employee ID : 1145</div>
                            <div class="small doj text-muted">Date of Join : 16 Apr 2017</div>
                            <div class="staff-msg"><a class="btn btn-custom" data-bs-target="#profile_info" data-bs-toggle="modal" href="javascript:void(0)">Edit Profile</a></div>
                          </div>
                        </div>
                        <div class="col-md-7">
                          <ul class="personal-info">
                            <li>
                              <div class="title">Phone:</div>
                              <div class="text"><a href="tel:0322-8227779">0322-8227779</a></div>
                            </li>
                            <li>
                              <div class="title">Email:</div>
                              <div class="text"><a href="mailto:AliHassan.Khan@abtach.org">AliHassan.Khan@abtach.org</a></div>
                            </li>
                            <li>
                              <div class="title">NIC No:</div>
                              <div class="text">35201-6830038-9</div>
                            </li>
                            <li>
                              <div class="title">Address:</div>
                              <div class="text">Building # 14-K, 2nd Floor, PECHS, Block 6, Ext, Karachi.</div>
                            </li>
                            <li>
                              <div class="title">Birth Date:</div>
                              <div class="text">24 Feb 1990</div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="pro-edit"><a data-bs-target="#profile_info" data-bs-toggle="modal" class="edit-icon" href="javascript:void(0)"><i class="fa fa-pencil"></i></a></div> -->
                  </div>
                </div>
              </div>
            </div>
        </div>
</template>