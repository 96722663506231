<template>
<div class="card">
                <div class="card-header">
                  <h4 class="card-title mb-0">Checkbox and Radio Addons</h4>
                </div>
                <div class="card-body">
                  <form action="#">
                    <div class="form-group row">
                      <label class="col-form-label col-lg-2">Checkbox</label>
                      <div class="col-lg-10">
                        <div class="input-group">
                          <span class="input-group-text">
                            <input type="checkbox">
                          </span>
                          <input type="text" class="form-control">
                        </div>
                      </div>
                    </div>
                    <div class="form-group row mb-0">
                      <label class="col-form-label col-lg-2">Radio</label>
                      <div class="col-lg-10">
                        <div class="input-group">
                          <span class="input-group-text">
                            <input type="radio">
                          </span>
                          <input type="text" class="form-control">
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
</template>