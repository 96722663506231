<template>
<div class="fixed-header">
                  <div class="row">
                    <div class="col-6">
                      <h4 class="page-title mb-0">Contacts</h4>
                    </div>
                     <div class="col-6">
                      <div class="navbar justify-content-end">
                        <div class="search-box m-t-0">
                          <div class="input-group input-group-sm">
                            <input type="text" class="form-control" placeholder="Search">
                            <button class="btn" type="button"><i class="fa fa-search"></i></button>
                          </div>
                        </div>
                        <ul class="nav float-end custom-menu">
                          <li class="nav-item dropdown dropdown-action">
                            <a href="" class="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa fa-cog"></i></a>
                            <div class="dropdown-menu">
                              <a class="dropdown-item" href="javascript:void(0)">Menu 1</a>
                              <a class="dropdown-item" href="javascript:void(0)">Menu 2</a>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
</template>