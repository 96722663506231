<template>
  <div class="salary">
     <div class="main-wrapper">
        <main-header></main-header>
        <sidebar></sidebar>
        <!-- Page Wrapper -->
        <div class="page-wrapper">
           <!-- Page Content -->
           <div class="content container-fluid">
              <!-- Page Header -->
              <div class="page-header">
                 <div class="row align-items-center">
                    <div class="col">
                       <h3 class="page-title">Salary Slips</h3>
                       <ul class="breadcrumb">
                          <li class="breadcrumb-item">
                             <router-link to="/index">Dashboard</router-link>
                          </li>
                          <li class="breadcrumb-item active">My Salary Details</li>
                          <li class="breadcrumb-item active">Salary Slips</li>
                       </ul>
                    </div>
                 </div>
              </div>
              <!-- /Page Header -->
              <div class="row">
                 <div class="col-md-12">
                  <div class="card">
                     <div class="card-body">
                        <div class="table-responsive">
                       <table class="table table-striped custom-table datatable" id="salarytable">
                          <thead>
                             <tr>
                                <th>Code</th>
                                <th>Name</th>
                                <th>Salary Month</th>
                                <th>Action</th>
                             </tr>
                          </thead>
                          <tbody>
                             <tr>
                                <td>1145</td>
                                <td>Ali Hassan Khan</td>
                                <td>Jun-2022</td>
                                <td>
                                   <router-link class="btn btn-sm btnPrimary text-white" to="/salary-view">Print PDF</router-link>
                                </td>
                             </tr>
                             <tr>
                                <td>1145</td>
                                <td>Ali Hassan Khan</td>
                                <td>May-2022</td>
                                <td>
                                   <router-link class="btn btn-sm btnPrimary text-white" to="/salary-view">Print PDF</router-link>
                                </td>
                             </tr>
                          </tbody>
                       </table>
                    </div>
                     </div>
                  </div>
                    
                 </div>
              </div>
           </div>
           <!-- /Page Content -->
        </div>
        <!-- /Page Wrapper -->
     </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  export default {
  data() {
    },
    components: {
   
    },
    mounted() {
    },
    name: 'salary'
  }
</Script>