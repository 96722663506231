<template>
    <div class="col-md-12">
      <div class="card position-relative">
        <div class="card-body">
          <div class="welcome-box">
        <div class="welcome-det">
          <h3>Welcome, <span>Ali Hassan Khan</span></h3>
        </div>
        <div class="welcome-content mt-3">
          <p class="mb-1">You have done 68% 😎 more sales today.</p>
          <p>Check your new badge in your profile.</p>
          <router-link to="profile" class="cta-btn">View Profile</router-link>
        </div>
        <img alt="Congratulations John" src="	https://demo.dashboardmarket.com/hexadash-html/ltr/img/danial.png">
      </div>
        </div>
      </div>
    </div>
</template>
