<template>
  <div class="salary">
     <div class="main-wrapper">
        <main-header></main-header>
        <sidebar></sidebar>
        <!-- Page Wrapper -->
        <div class="page-wrapper">
           <!-- Page Content -->
           <div class="content container-fluid">
              <!-- Page Header -->
              <headerprofile />
              <!-- /Page Header -->
              <div class="row">
                 <div class="col-md-12">
                  <div class="card">
                     <div class="card-body">
                        <div class="table-responsive">
                       <table class="table table-striped custom-table datatable" id="salarytable">
                          <thead>
                             <tr>
                                <th>Month</th>
                                <th>Sale</th>
                                <th>Expense</th>
                                <th>Profit</th>
                                <th>Incentive</th>
                                <th>BC</th>
                             </tr>
                          </thead>
                          <tbody>
                             <tr>
                                <td>Dec-2021</td>
                                <td>$290,626.63</td>
                                <td>$242,124.00</td>
                                <td>$48,502.63</td>
                                <td>PKR 363,770.00</td>
                                <td>PKR 363,770.00</td>
                             </tr>
                             <tr>
                                <td>Jan-2022</td>
                                <td>$290,626.63</td>
                                <td>$242,124.00</td>
                                <td>$48,502.63</td>
                                <td>PKR 363,770.00</td>
                                <td>PKR 363,770.00</td>
                             </tr>
                          </tbody>
                       </table>
                    </div>
                     </div>
                  </div>
                    
                 </div>
              </div>
           </div>
           <!-- /Page Content -->
        </div>
        <!-- /Page Wrapper -->
     </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  export default {
  data() {
    },
    components: {
   
    },
    mounted() {
    },
    name: 'salary'
  }
</Script>