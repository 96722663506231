<template>

  <div class="profile">

    <div class="main-wrapper">

      <main-header></main-header>

      <sidebar></sidebar>

       <!-- Page Wrapper -->
       <div class="page-wrapper">
      
        <!-- Page Content -->
        <div class="content container-fluid">
        
          <div class="page-header">
            <div class="row">
                    <div class="col-sm-12">
                      <h3 class="page-title">Profile</h3>
                      <ul class="breadcrumb">
                          <li class="breadcrumb-item">
                            <router-link to="/index">Dashboard</router-link>
                          </li>
                          <li class="breadcrumb-item active">My Profile</li>
                          <li class="breadcrumb-item active">Profile</li>
                      </ul>
                    </div>
                </div>
              </div>

        <profilecardfirst />
          
        <profilecardsecond />
          
        <profilecontent />

        </div>
        <!-- /Page Content -->
        
        <modalprofile />
        
      </div>
      <!-- /Page Wrapper -->

    </div>

  </div>
  
</template>
<script>
  import Vue from 'vue'
  export default {
    components: {
   
    },
    mounted() {


    },
    name: 'profile'
  }
</Script>