<template>
 <!-- Page Header -->
                    <div class="page-header">
                        <div class="row align-items-center">
                            <div class="col">
                                <h3 class="page-title">Leave Type</h3>
                                <ul class="breadcrumb">
                                    <li class="breadcrumb-item"><router-link to="/index">Dashboard</router-link></li>
                                    <li class="breadcrumb-item active">Leave Type</li>
                                </ul>
                            </div>
                            <div class="col-auto float-end ms-auto">
                                <a href="javascript:void(0)" class="btn add-btn" data-bs-toggle="modal" data-bs-target="#add_leavetype"><i class="fa fa-plus"></i> Add Leave Type</a>
                            </div>
                        </div>
                    </div>
                    <!-- /Page Header -->
</template>