<template>
 <!-- Page Header -->
                            <div class="page-header mb-2">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <h3 class="page-title">Approval Settings</h3>
                                    </div>
                                </div>
                            </div>
                            <!-- /Page Header -->

</template>