<template>
<div class="col-md-6 d-flex">
                  <div class="card flex-fill">
                    <div class="card-body">
                      <h3 class="card-title text-center">Latest Jobs</h3>
                      <ul class="list-group">
                          <li class="list-group-item list-group-item-action">UI Developer <span class="float-end text-sm text-muted">1 Hours ago</span></li>
                          <li class="list-group-item list-group-item-action">Android Developer <span class="float-end text-sm text-muted">1 Days ago</span></li>
                          <li class="list-group-item list-group-item-action">IOS Developer<span class="float-end text-sm text-muted">2 Days ago</span></li>
                          <li class="list-group-item list-group-item-action">PHP Developer<span class="float-end text-sm text-muted">3 Days ago</span></li>
                          <li class="list-group-item list-group-item-action">UI Developer<span class="float-end text-sm text-muted">3 Days ago</span></li>
                          <li class="list-group-item list-group-item-action">PHP Developer<span class="float-end text-sm text-muted">4 Days ago</span></li>
                          <li class="list-group-item list-group-item-action">UI Developer<span class="float-end text-sm text-muted">4 Days ago</span></li>
                          <li class="list-group-item list-group-item-action">Android Developer<span class="float-end text-sm text-muted">6 Days ago</span></li>
                      </ul>
                    </div>
                  </div>
                </div>
</template>