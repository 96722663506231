<template>
<div class="card">
                  <div class="card-body">
                    <p class="m-b-30">Click button to answer your question.</p>
                    <div class="row">
                      <div class="col-md-6 text-center m-b-30">
                        <router-link to="/questions" class="btn btn-primary w-100 submit-btn">Html</router-link>
                      </div>
                      <div class="col-md-6 text-center m-b-30">
                        <router-link to="/questions" class="btn btn-primary w-100 submit-btn">Css</router-link>
                      </div>
                      <div class="col-md-6 text-center m-b-30">
                        <router-link to="/questions" class="btn btn-primary w-100 submit-btn">Design</router-link>
                      </div>
                      <div class="col-md-6 text-center m-b-30">
                        <router-link to="/questions" class="btn btn-primary w-100 submit-btn">Javascript</router-link>
                      </div>
                    </div>
                  </div>
                </div>
</template>