<template>
<div class="col-lg-4 col-md-4">
              <div class="dash-sidebar">
                <section>
                  <h5 class="dash-title">Projects</h5>
                  <div class="card">
                    <div class="card-body">
                      <div class="time-list">
                        <div class="dash-stats-list">
                          <h4>71</h4>
                          <p>Total Tasks</p>
                        </div>
                        <div class="dash-stats-list">
                          <h4>14</h4>
                          <p>Pending Tasks</p>
                        </div>
                      </div>
                      <div class="request-btn">
                        <div class="dash-stats-list">
                          <h4>2</h4>
                          <p>Total Projects</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section>
                  <h5 class="dash-title">Your Leave</h5>
                  <div class="card">
                    <div class="card-body">
                      <div class="time-list">
                        <div class="dash-stats-list">
                          <h4>4.5</h4>
                          <p>Leave Taken</p>
                        </div>
                        <div class="dash-stats-list">
                          <h4>12</h4>
                          <p>Remaining</p>
                        </div>
                      </div>
                      <div class="request-btn">
                        <a class="btn btn-primary" href="javascript:void(0)">Apply Leave</a>
                      </div>
                    </div>
                  </div>
                </section>
                <section>
                  <h5 class="dash-title">Your time off allowance</h5>
                  <div class="card">
                    <div class="card-body">
                      <div class="time-list">
                        <div class="dash-stats-list">
                          <h4>5.0 Hours</h4>
                          <p>Approved</p>
                        </div>
                        <div class="dash-stats-list">
                          <h4>15 Hours</h4>
                          <p>Remaining</p>
                        </div>
                      </div>
                      <div class="request-btn">
                        <a class="btn btn-primary" href="javascript:void(0)">Apply Time Off</a>
                      </div>
                    </div>
                  </div>
                </section>
                <section>
                  <h5 class="dash-title">Upcoming Holiday</h5>
                  <div class="card">
                    <div class="card-body text-center">
                      <h4 class="holiday-title mb-0">Mon 20 May 2019 - Ramzan</h4>
                    </div>
                  </div>
                </section>
              </div>
            </div>
</template>