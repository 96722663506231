<template>
  <!-- Profile Modal -->
  <div id="profile_info" class="modal custom-modal fade" role="dialog">
     <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
           <div class="modal-header">
              <h5 class="modal-title">Profile Information</h5>
              <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
              </button>
           </div>
           <div class="modal-body">
              <form enctype="multipart/form-data" @submit="submitForm">
                 <div class="row">
                    <div class="col-md-12">
                     
                              
                       <div class="profile-img-wrap edit-img">
                        <div v-if="showPreview === true">
                           <img class="inline-block" v-bind:src="imagePreview"/>
                        </div>
                        <img class="inline-block" v-else src="../../../assets/img/profiles/avatar-02.jpg" alt="user">
                          <div class="fileupload btn">
                           
                             <span class="btn-text">edit</span>
                             <input class="upload" accept="image/png, image/jpeg" type="file" @change="onChange">
                          </div>
                       </div>
                       <div class="row">
                          <div class="col-md-6">
                             <div class="form-group">
                                <label>Full Name</label>
                                <input type="text" class="form-control" value="John">
                             </div>
                          </div>
                          <div class="col-md-6">
                             <div class="form-group">
                                <label>Email</label>
                                <input type="email" class="form-control" value="email@gmail.com">
                             </div>
                          </div>
                       </div>
                    </div>
                 </div>
                 <div class="row">
                    <div class="col-md-6">
                       <div class="form-group">
                          <label>Phone Number</label>
                          <input type="tell" class="form-control" value="631-889-3206">
                       </div>
                    </div>
                    <div class="col-md-6">
                       <div class="form-group">
                          <label>NIC No</label>
                          <input type="numeber" class="form-control" value="631-889-3206">
                       </div>
                    </div>
                    <div class="col-md-6">
                       <div class="form-group">
                          <label>Address</label>
                          <input type="text" class="form-control" value="4487 Snowbird Lane">
                       </div>
                    </div>
                    <div class="col-md-6">
                       <div class="form-group">
                          <label>Birth Date</label>
                          <input type="date" class="form-control">
                       </div>
                    </div>
                 </div>
                 <div class="submit-section">
                    <button class="btn btn-primary cta-btn">Submit</button>
                    <button type="button" class="cancel-cta"  data-bs-dismiss="modal" aria-label="Close">CANCEL</button>
                 </div>
              </form>
           </div>
        </div>
     </div>
  </div>
  <!-- /Profile Modal -->
  <!-- Personal Info Modal -->
  <div id="personal_info_modal" class="modal custom-modal fade" role="dialog">
     <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
           <div class="modal-header">
              <h5 class="modal-title">Personal Information</h5>
              <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
              </button>
           </div>
           <div class="modal-body">
              <form>
                 <div class="row">
                    <div class="col-md-6">
                       <div class="form-group">
                          <label>Passport No</label>
                          <input type="text" class="form-control">
                       </div>
                    </div>
                    <div class="col-md-6">
                       <div class="form-group">
                          <label>Passport Expiry Date</label>
                          <div class="cal-icon">
                             <datepicker v-model="enddate"  class="picker" 
                                :editable="true"
                                :clearable="false" />
                          </div>
                       </div>
                    </div>
                    <div class="col-md-6">
                       <div class="form-group">
                          <label>Tel</label>
                          <input class="form-control" type="text">
                       </div>
                    </div>
                    <div class="col-md-6">
                       <div class="form-group">
                          <label>Nationality <span class="text-danger">*</span></label>
                          <input class="form-control" type="text">
                       </div>
                    </div>
                    <div class="col-md-6">
                       <div class="form-group">
                          <label>Religion</label>
                          <div class="cal-icon">
                             <input class="form-control" type="text">
                          </div>
                       </div>
                    </div>
                    <div class="col-md-6">
                       <div class="form-group">
                          <label>Marital status <span class="text-danger">*</span></label>
                          <vue-select :options="perosnalprofile" />
                       </div>
                    </div>
                    <div class="col-md-6">
                       <div class="form-group">
                          <label>Employment of spouse</label>
                          <input class="form-control" type="text">
                       </div>
                    </div>
                    <div class="col-md-6">
                       <div class="form-group">
                          <label>No. of children </label>
                          <input class="form-control" type="text">
                       </div>
                    </div>
                 </div>
                 <div class="submit-section">
                    <button class="btn btn-primary cta-btn">Submit</button>
                    <button type="button" class="cancel-cta"  data-bs-dismiss="modal" aria-label="Close">CANCEL</button>
                 </div>
              </form>
           </div>
        </div>
     </div>
  </div>
  <!-- /Personal Info Modal -->
  <!-- Family Info Modal -->
  <div id="family_info_modal" class="modal custom-modal fade" role="dialog">
     <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
           <div class="modal-header">
              <h5 class="modal-title"> Family Informations</h5>
              <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
              </button>
           </div>
           <div class="modal-body">
              <form>
                 <div class="form-scroll">
                    <div class="card">
                       <div class="card-body">
                          <h3 class="card-title">Family Member <a href="javascript:void(0);" class="delete-icon"><i class="fa fa-trash-o"></i></a></h3>
                          <div class="row">
                             <div class="col-md-6">
                                <div class="form-group">
                                   <label>Name <span class="text-danger">*</span></label>
                                   <input class="form-control" type="text">
                                </div>
                             </div>
                             <div class="col-md-6">
                                <div class="form-group">
                                   <label>Relationship <span class="text-danger">*</span></label>
                                   <input class="form-control" type="text">
                                </div>
                             </div>
                             <div class="col-md-6">
                                <div class="form-group">
                                   <label>Date of birth <span class="text-danger">*</span></label>
                                   <input class="form-control" type="text">
                                </div>
                             </div>
                             <div class="col-md-6">
                                <div class="form-group">
                                   <label>Phone <span class="text-danger">*</span></label>
                                   <input class="form-control" type="text">
                                </div>
                             </div>
                          </div>
                       </div>
                    </div>
                    <div class="card">
                       <div class="card-body">
                          <h3 class="card-title">Education Informations <a href="javascript:void(0);" class="delete-icon"><i class="fa fa-trash-o"></i></a></h3>
                          <div class="row">
                             <div class="col-md-6">
                                <div class="form-group">
                                   <label>Name <span class="text-danger">*</span></label>
                                   <input class="form-control" type="text">
                                </div>
                             </div>
                             <div class="col-md-6">
                                <div class="form-group">
                                   <label>Relationship <span class="text-danger">*</span></label>
                                   <input class="form-control" type="text">
                                </div>
                             </div>
                             <div class="col-md-6">
                                <div class="form-group">
                                   <label>Date of birth <span class="text-danger">*</span></label>
                                   <input class="form-control" type="text">
                                </div>
                             </div>
                             <div class="col-md-6">
                                <div class="form-group">
                                   <label>Phone <span class="text-danger">*</span></label>
                                   <input class="form-control" type="text">
                                </div>
                             </div>
                          </div>
                          <div class="add-more">
                             <a href="javascript:void(0);"><i class="fa fa-plus-circle"></i> Add More</a>
                          </div>
                       </div>
                    </div>
                 </div>
                 <div class="submit-section">
                    <button class="btn btn-primary cta-btn">Submit</button>
                    <button type="button" class="cancel-cta"  data-bs-dismiss="modal" aria-label="Close">CANCEL</button>
                 </div>
              </form>
           </div>
        </div>
     </div>
  </div>
  <!-- /Family Info Modal -->
  <!-- Emergency Contact Modal -->
  <div id="emergency_contact_modal" class="modal custom-modal fade" role="dialog">
     <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
           <div class="modal-header">
              <h5 class="modal-title">Personal Information</h5>
              <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
              </button>
           </div>
           <div class="modal-body">
              <form>
                 <div class="card">
                    <div class="card-body">
                       <div class="row">
                          <div class="col-md-6">
                             <div class="form-group">
                                <label>Name <span class="text-danger">*</span></label>
                                <input type="text" class="form-control">
                             </div>
                          </div>
                          <div class="col-md-6">
                             <div class="form-group">
                                <label>Relationship <span class="text-danger">*</span></label>
                                <input class="form-control" type="text">
                             </div>
                          </div>
                          <div class="col-md-12">
                             <div class="form-group">
                                <label>Phone <span class="text-danger">*</span></label>
                                <input class="form-control" type="text">
                             </div>
                          </div>
                       </div>
                    </div>
                 </div>
                 <div class="submit-section mt-2">
                    <button class="btn btn-primary cta-btn">Submit</button>
                    <button type="button" class="cancel-cta"  data-bs-dismiss="modal" aria-label="Close">CANCEL</button>
                 </div>
              </form>
           </div>
        </div>
     </div>
  </div>
  <!-- /Emergency Contact Modal -->
  <!-- Education Modal -->
  <div id="education_info" class="modal custom-modal fade" role="dialog">
     <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
           <div class="modal-header">
              <h5 class="modal-title"> Education Informations</h5>
              <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
              </button>
           </div>
           <div class="modal-body">
              <form @submit.prevent>
                 <div class="form-scroll">
                    <div class="card mb-0">
                       <div class="card-body" v-for="key in count" :key="key">
                          <h3 class="card-title">Education Informations<a class="add-more" href="javascript:void(0);" @click="add"><i class="fa fa-plus-circle"></i></a> <a href="javascript:void(0);" class="delete-icon"  @click="remove"><i class="fa fa-trash-o"></i></a></h3>
                          <div class="row">
                             <div class="col-md-6">
                                <div class="form-group form-focus focused">
                                   <input type="text" class="form-control floating" v-model="values['institution'+key]">
                                   <label class="focus-label">Institution</label>
                                </div>
                             </div>
                             <div class="col-md-6">
                                <div class="form-group form-focus focused">
                                   <input type="text" class="form-control floating" v-model="values['subject'+key]"> 
                                   <label class="focus-label">Subject</label>
                                </div>
                             </div>
                             <div class="col-md-6">
                                <div class="form-group form-focus focused">
                                   <div class="cal-icon">
                                      <datepicker v-model="values['startdate'+key]"  class="picker" 
                                         :editable="true"
                                         :clearable="false" />
                                   </div>
                                </div>
                             </div>
                             <div class="col-md-6">
                                <div class="form-group form-focus focused">
                                   <div class="cal-icon">
                                      <datepicker v-model="values['enddate'+key]" class="picker" 
                                         :editable="true"
                                         :clearable="false" />
                                   </div>
                                </div>
                             </div>
                             <div class="col-md-6">
                                <div class="form-group form-focus focused">
                                   <input type="text"  class="form-control floating" v-model="values['degree'+key]">
                                   <label class="focus-label">Degree</label>
                                </div>
                             </div>
                             <div class="col-md-6">
                                <div class="form-group form-focus focused">
                                   <input type="text"  class="form-control floating" v-model="values['Grade'+key]" :id="key" > 
                                   <label class="focus-label">Grade</label>
                                </div>
                             </div>
                          </div>
                       </div>
                    </div>
                 </div>
                 <div class="submit-section mt-2">
                    <button class="btn btn-primary cta-btn" @click="submit(event)">Submit</button>
                    <button type="button" class="cancel-cta"  data-bs-dismiss="modal" aria-label="Close">CANCEL</button>
                 </div>
              </form>
           </div>
        </div>
     </div>
  </div>
  <!-- /Education Modal -->
  <!-- Experience Modal -->
  <div id="experience_info" class="modal custom-modal fade" role="dialog">
     <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
           <div class="modal-header">
              <h5 class="modal-title">Experience Informations</h5>
              <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
              </button>
           </div>
           <div class="modal-body">
              <form>
                 <div class="form-scroll">
                    <div class="card">
                       <div class="card-body">
                          <h3 class="card-title">Experience Informations <a href="javascript:void(0);" class="delete-icon"><i class="fa fa-trash-o"></i></a></h3>
                          <div class="row">
                             <div class="col-md-6">
                                <div class="form-group form-focus">
                                   <input type="text" class="form-control floating" value="Digital Devlopment Inc">
                                </div>
                             </div>
                             <div class="col-md-6">
                                <div class="form-group form-focus">
                                   <input type="text" class="form-control floating" value="United States">
                                </div>
                             </div>
                             <div class="col-md-6">
                                <div class="form-group form-focus">
                                   <input type="text" class="form-control floating" value="Web Developer">
                                </div>
                             </div>
                             <div class="col-md-6">
                                <div class="form-group form-focus">
                                   <div class="cal-icon">
                                      <datepicker v-model="startdate3"  class="picker" 
                                         :editable="true"
                                         :clearable="false" />
                                   </div>
                                </div>
                             </div>
                             <div class="col-md-6">
                                <div class="form-group form-focus">
                                   <div class="cal-icon">
                                      <datepicker v-model="enddate3"  class="picker" 
                                         :editable="true"
                                         :clearable="false" />
                                   </div>
                                </div>
                             </div>
                          </div>
                       </div>
                    </div>
                    <div class="card">
                       <div class="card-body">
                          <h3 class="card-title">Experience Informations <a href="javascript:void(0);" class="delete-icon"><i class="fa fa-trash-o"></i></a></h3>
                          <div class="row">
                             <div class="col-md-6">
                                <div class="form-group form-focus">
                                   <input type="text" class="form-control floating" value="Digital Devlopment Inc">
                                </div>
                             </div>
                             <div class="col-md-6">
                                <div class="form-group form-focus">
                                   <input type="text" class="form-control floating" value="United States">
                                </div>
                             </div>
                             <div class="col-md-6">
                                <div class="form-group form-focus">
                                   <input type="text" class="form-control floating" value="Web Developer">
                                </div>
                             </div>
                             <div class="col-md-6">
                                <div class="form-group form-focus">
                                   <div class="cal-icon">
                                      <datepicker v-model="startdate4"  class="picker" 
                                         :editable="true"
                                         :clearable="false" />
                                   </div>
                                </div>
                             </div>
                             <div class="col-md-6">
                                <div class="form-group form-focus">
                                   <div class="cal-icon">
                                      <datepicker v-model="enddate4"  class="picker" 
                                         :editable="true"
                                         :clearable="false" />
                                   </div>
                                </div>
                             </div>
                          </div>
                          <div class="add-more">
                             <a href="javascript:void(0);"><i class="fa fa-plus-circle"></i> Add More</a>
                          </div>
                       </div>
                    </div>
                 </div>
                 <div class="submit-section">
                    <button class="btn btn-primary cta-btn">Submit</button>
                    <button type="button" class="cancel-cta"  data-bs-dismiss="modal" aria-label="Close">CANCEL</button>
                 </div>
              </form>
           </div>
        </div>
     </div>
  </div>
  <!-- /Experience Modal -->
  <!-- Bank Modal -->
  <div id="bank_info" class="modal custom-modal fade" role="dialog">
     <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
           <div class="modal-header">
              <h5 class="modal-title">Bank Details</h5>
              <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
              </button>
           </div>
           <div class="modal-body">
              <form>
                 <div class="form-scroll">
                    <div class="row">
                       <div class="col-md-6">
                          <div class="form-group">
                             <label>Employee Code </label>
                             <input class="form-control" type="text" value="1145">
                          </div>
                       </div>
                       <div class="col-md-6">
                          <div class="form-group">
                             <label>Employee Name</label>
                             <input class="form-control" type="text" value="Ali Hassan Khan">
                          </div>
                       </div>
                       <div class="col-md-6">
                          <div class="form-group">
                             <label>Bank Name<span class="text-danger">*</span></label>
                             <div class="form-group">
                                <vue-select :options="attendancefilter" />
                             </div>
                          </div>
                       </div>
                       <div class="col-md-6">
                          <div class="form-group">
                             <label>Your ATM/Cheque Book is activated? <span class="text-danger">*</span></label>
                             <input class="form-control" type="text">
                          </div>
                       </div>
                       <div class="col-md-6">
                          <div class="form-group">
                             <label>Select Branch<span class="text-danger">*</span></label>
                             <div class="form-group">
                                <vue-select :options="attendancefilter" />
                             </div>
                          </div>
                       </div>
                       <div class="col-md-6">
                          <div class="form-group">
                             <label>Account Number (without branch code) <span class="text-danger">*</span></label>
                             <input class="form-control" type="text">
                          </div>
                       </div>
                       <div class="col-md-6">
                          <div class="form-group">
                             <label>IBAN (mentioned on cheque book) <span class="text-danger">*</span></label>
                             <input class="form-control" type="text">
                          </div>
                       </div>
                       <div class="col-md-6">
                          <div class="form-group">
                             <label>Account Title (mentioned on cheque book)<span class="text-danger">*</span></label>
                             <input class="form-control" type="text">
                          </div>
                       </div>
                    </div>
                 </div>
                 <div class="submit-section">
                    <button class="btn btn-primary cta-btn">Submit</button>
                    <button type="button" class="cancel-cta"  data-bs-dismiss="modal" aria-label="Close">CANCEL</button>
                 </div>
              </form>
           </div>
        </div>
     </div>
  </div>
  <!-- /Bank Modal -->
</template>
<script>
  import Vue from 'vue'
  import { ref } from 'vue'
  // const currentDate = ref(new Date())
  // const currentDate1 = ref(new Date())
  // const currentDate2 = ref(new Date())
  // const currentDate3 = ref(new Date())
  // const currentDate4 = ref(new Date())
  // const currentDate5 = ref(new Date())
  // const currentDate6 = ref(new Date())
  // const currentDate7 = ref(new Date())
  // const currentDate8 = ref(new Date())
  // const currentDate9 = ref(new Date())
  
  export default {
      setup(){
         const file = ref();
         const showPreview =  ref();
			const imagePreview = ref();



         const onChange= (e) => {
            file.value = e.target.files[0];
            console.log(e );

            let reader  = new FileReader();

            reader.addEventListener("load", function () {
               console.log(reader.result);
                  showPreview.value = true;
                  imagePreview.value = reader.result;
   
            }.bind(this), false);

            if( file.value ){
					/*
						Ensure the file is an image file.
					*/
					if ( /\.(jpe?g|png|gif)$/i.test( file.value.name ) ) {
						/*
							Fire the readAsDataURL method which will read the file in and
							upon completion fire a 'load' event which we will listen to and
							display the image in the preview.
						*/
						reader.readAsDataURL( file.value );
					}
				}


         }

         return{
            onChange,
            imagePreview,
            showPreview
         }
      },

      data() {
      return {
         count: 1,
         values: {},
         // genderprofile: ["Male", "Female"],
         // departmenprofile: ["Select Department", "Web Development", "IT Management", "Marketing"],
         // designation: ["Select Designation", "Web Designer", "Web Developer", "Android Developer"],
         // reports: ["-", "Wilmer Deluna", "Lesley Grauer", "Jeffery Lalor"],
         // perosnalprofile: ["-", "Single", "Married"],
         // startdate: currentDate,
         // enddate: currentDate1,
         // startdate1: currentDate2,
         // enddate1: currentDate3,
         // startdate2: currentDate4,
         // enddate2: currentDate5,
         // startdate3: currentDate6,
         // enddate3: currentDate7,
         // startdate4: currentDate8,
         // enddate4: currentDate9,
   
      }
      },
      components: {
      
      },
      methods:{
         add: function(){
         this.count++;
         },
         remove: function () {
         this.count--;
         },
         submit: function(){
         for (var key of Object.keys(this.values)) {
               console.log(key + "->" + this.values[key])
         }
         }
      },
      mounted() {
      },
  }
</script>