<template>
<div class="row">
            <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <div class="card dash-widget">
                <div class="card-body">
                  <span class="dash-widget-icon"><i class="fa fa-file-text-o"></i></span>
                  <div class="dash-widget-info">
                    <h3>110</h3>
                    <span>Offered</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <div class="card dash-widget">
                <div class="card-body">
                  <span class="dash-widget-icon"><i class="fa fa-clipboard"></i></span>
                  <div class="dash-widget-info">
                    <h3>40</h3>
                    <span>Applied</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <div class="card dash-widget">
                <div class="card-body">
                  <span class="dash-widget-icon"><i class="fa fa-retweet"></i></span>
                  <div class="dash-widget-info">
                    <h3>374</h3>
                    <span>Visited</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <div class="card dash-widget">
                <div class="card-body">
                  <span class="dash-widget-icon"><i class="fa fa-floppy-o"></i></span>
                  <div class="dash-widget-info">
                    <h3>220</h3>
                    <span>Saved</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
</template>