<template>
	<div class="login-section bg-white">
		<div class="row">
			<div class="col-md-7 d-none d-lg-flex justify-content-center align-items-center auth-bg height-100">
				<img class="img-fluid" src="../../../../assets/img/auth-v2-login-illustration-light.d1fd488d.png">
			</div>
			<div class="col-md-5 d-flex align-items-center justify-content-start">
				<div class="auth-form p-5 col-12">
					<img src="../../../../assets/img/authlogo.svg" alt="Cyberonix Consulting" class="img-fluid">
					<h2>Welcome!</h2>
					<p class="mb-4">Please sign-in to your account and start the adventure</p>
					<p v-if="fieldError" class="badge bg-inverse-danger pt-2 pb-2 d-block">{{ fieldError }}</p>
					<form class="login" @submit.prevent="login">
						<div class="row">
							<div class="col-md-12">
								<div class="form-group">
									<label class="mb-2">Email Address</label>
									<input type="email" name="email" class="form-control" id="inputEmail4" v-model="form.email">
								</div>
							</div>
							<div class="col-md-12">
								<div class="form-group password-icon">
									<label class="mb-2">Password</label>
									<span class="fa fa-eye-slash toggle-password pt-4"></span>
									<input name="password" type="password" class="form-control pass-input" v-model="form.password"/>
								</div>
							</div>
							<div class="col-12">
								<div class="row">
									<div class="col-md-6">
									<router-link class="text-muted mt-4 d-block" to="/forgot-password">
										Forgot password?
									</router-link>
									</div>
									<div class="col-md-6">
										<div class="form-group text-center">
											<button class="cta-btn" type="submit" value="Login">Login</button>
										</div>
									</div>
								</div>
							</div>
						</div>
                    </form>
				</div>
				
			</div>
		</div>
	</div>
</template>
<script>
import { reactive,ref,onMounted } from 'vue';
import { useRouter } from 'vue-router';
export default {
	setup(){
		const router = useRouter();

		let form = reactive ({
                email: '',
                password: '' ,
        });

		let fieldError = ref('')

		onMounted(() => {
			if ($('.toggle-password').length > 0) {
				$(document).on('click', '.toggle-password', function() {
					$(this).toggleClass("fa-eye fa-eye-slash");
					var input = $(".pass-input");
					if (input.attr("type") == "password") {
						input.attr("type", "text");
					} else {
						input.attr("type", "password");
					}
				});
			}
		})

		const login = async() =>{
			await axios.post('https://reqres.in/api/login',form).then(response=>{
				if(response.data.token !== null){
					// store.setAdmin(true);
					// store.setToken(response.data.token);
					// router.push({name:'Dashboard'});
					console.log('hello');
				}
			}).catch(error=>{
				fieldError.value =  error.response.data.error;
            })
		}

		return {
                form,
				fieldError,
                login,
        }
	}

}
</script>
