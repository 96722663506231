<template>
<div class="row">
            <div class="col-md-3">
              <div class="stats-info">
                <h6>Joining</h6>
                <h4>12 <span>This Month</span></h4>
              </div>
            </div>
            <div class="col-md-3">
              <div class="stats-info">
                <h6>Renewal</h6>
                <h4>3 <span>This Month</span></h4>
              </div>
            </div>
            <div class="col-md-3">
              <div class="stats-info">
                <h6>Renewal</h6>
                <h4>0 <span>Next Month</span></h4>
              </div>
            </div>
            <div class="col-md-3">
              <div class="stats-info">
                <h6>Total Companies</h6>
                <h4>312</h4>
              </div>
            </div>
          </div>
</template>