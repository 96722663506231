<template>
  <!-- Page Tab -->
          <div class="page-menu">
            <div class="row">
              <div class="col-sm-12">
                <ul class="nav nav-tabs nav-tabs-bottom">
                  <li class="nav-item">
                    <a class="nav-link active" data-bs-toggle="tab" href="#tab_additions">Additions</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" data-bs-toggle="tab" href="#tab_overtime">Overtime</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" data-bs-toggle="tab" href="#tab_deductions">Deductions</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- /Page Tab -->
</template>