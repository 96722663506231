<template>

  <div class="dashboard-default">
     <div class="card">
        <div class="card-body pb-0 activity-height">
           <div class="card-header pb-0 border-0">
              <div class="d-flex justify-content-between">
                 <div class="flex-grow-1">
                    <p class="square-after f-w-600 header-text-primary">Recent Activity<i class="fa fa-circle"> </i></p>
                    <h4>New &amp; Update</h4>
                 </div>
              </div>
           </div>
           <div class="card-body">
              <div class="activity-timeline">
                <div class="position-relative pe-3">
                  <div class="activity-line">
                  </div>
                  <div class="d-flex">
                      <div class="activity-dot-primary"></div>
                      <div class="flex-grow-1">
                        <span class="f-w-600 d-block">Notification for No Smoking in Office Premises</span>
                        <p class="mb-0"> The company’s No Smoking policy came into effect to prevent its premises with an intention to safeguard the health of all non-smokers. In recent events, it was found the use of tobacco/cigarettes/E-Cigarettes in office’s washrooms. We all are aware of the adverse effects of smoking which can deteriorate someone’s health. </p>
                      </div>
                  </div>
                  <div class="d-flex">
                      <div class="activity-dot-primary"></div>
                      <div class="flex-grow-1">
                        <span class="f-w-600 d-block">Notification for No Smoking in Office Premises</span>
                        <p class="mb-0"> The company’s No Smoking policy came into effect to prevent its premises with an intention to safeguard the health of all non-smokers. In recent events, it was found the use of tobacco/cigarettes/E-Cigarettes in office’s washrooms. We all are aware of the adverse effects of smoking which can deteriorate someone’s health. </p>
                      </div>
                  </div>
                  <div class="d-flex">
                      <div class="activity-dot-primary"></div>
                      <div class="flex-grow-1">
                        <span class="f-w-600 d-block">Notification for No Smoking in Office Premises</span>
                        <p class="mb-0"> The company’s No Smoking policy came into effect to prevent its premises with an intention to safeguard the health of all non-smokers. In recent events, it was found the use of tobacco/cigarettes/E-Cigarettes in office’s washrooms. We all are aware of the adverse effects of smoking which can deteriorate someone’s health. </p>
                      </div>
                  </div>
                  <div class="d-flex">
                      <div class="activity-dot-primary"></div>
                      <div class="flex-grow-1">
                        <span class="f-w-600 d-block">Notification for No Smoking in Office Premises</span>
                        <p class="mb-0"> The company’s No Smoking policy came into effect to prevent its premises with an intention to safeguard the health of all non-smokers. In recent events, it was found the use of tobacco/cigarettes/E-Cigarettes in office’s washrooms. We all are aware of the adverse effects of smoking which can deteriorate someone’s health. </p>
                      </div>
                  </div>
                  <div class="d-flex">
                      <div class="activity-dot-primary"></div>
                      <div class="flex-grow-1">
                        <span class="f-w-600 d-block">Notification for No Smoking in Office Premises</span>
                        <p class="mb-0"> The company’s No Smoking policy came into effect to prevent its premises with an intention to safeguard the health of all non-smokers. In recent events, it was found the use of tobacco/cigarettes/E-Cigarettes in office’s washrooms. We all are aware of the adverse effects of smoking which can deteriorate someone’s health. </p>
                      </div>
                  </div>
                  <div class="d-flex">
                      <div class="activity-dot-primary"></div>
                      <div class="flex-grow-1">
                        <span class="f-w-600 d-block">Notification for No Smoking in Office Premises</span>
                        <p class="mb-0"> The company’s No Smoking policy came into effect to prevent its premises with an intention to safeguard the health of all non-smokers. In recent events, it was found the use of tobacco/cigarettes/E-Cigarettes in office’s washrooms. We all are aware of the adverse effects of smoking which can deteriorate someone’s health. </p>
                      </div>
                  </div>
                </div>
              </div>
           </div>
        </div>
     </div>
  </div>
</template>
<script>
  import notification from '../../../../assets/json/notification.json';
  const images = require.context('../../../../assets/img/profiles', false, /\.png$|\.jpg$/)
    import Vue from 'vue'
    export default {
      data() {
      return {
        notification: notification
      }
    },
      components: {
      },
      mounted() {
      },
      methods: {
          loadImg(imgPath) {
              return images('./' + imgPath).default
      },
      },
      name: 'activities'
    }
</script>